<template>
    <div class="comic-progress-bar">
        <!-- Percent Done: {{ percentDone }} -->
        <div class="progress-done" :style="{ width: percentDone }" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ComicProgressBar",
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["currentPanel", "panelCount"]),
        percentDone() {
            const percent = (this.currentPanel / this.panelCount) * 100;
            return percent + "%";
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.comic-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: rgba(128, 128, 128, 0.5);
    // border: 1px dashed red;

    .progress-done {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%;
        background-color: #f15b00;
        transition: width 100ms;
    }
}
</style>