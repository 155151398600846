<template>
    <div
        class="comic-panel"
        :class="{ 'is-loading': loading }"
        ref="comicWindow"
    >
        <div v-if="loading" class="is-ghost">LOADING PANEL {{ panelNum }}</div>

        <div v-else class="transition-wrapper">
            <div
                class="contents"
                ref="comicContents"
                aria-live="polite"
                @click.exact="clickBackground"
                :style="{ '--player-scale': playerScale }"
            >
                <img
                    v-for="(layer, index) in layers"
                    @load="setImgDimentions(layer, $event)"
                    @click.exact.stop="clickLayer({ i: index, shiftKey: false })"
                    @click.shift.stop="clickLayer({ i: index, shiftKey: true })"
                    draggable="false"
                    :key="panelNum + index + layer.name"
                    :src="'/episodes/' + episode.number + '/' + layer.name"
                    :class="{
                        'is-playing': !paused && isActive,
                        'is-selected': layer.selected && editMode,
                        paused: paused && !editMode,
                    }"
                    :style="getLayerStyle(layer, index)"
                />
                <p
                    v-if="dialog && !hideDialog && !donePlaying"
                    class="text"
                    :class="{ top: dialog.top }"
                    :style="{ color: dialog.color }"
                >
                    {{ dialog.text }}
                </p>
                <div v-if="panelNum == 0" class="episode-title-wrapper">
                    <p class="episode-number">Episode #{{ episode.number }}</p>
                    <p class="episode-title">{{ episode.title }}</p>
                </div>
                <p v-if="donePlaying && !nextPanelLoaded" class="m-2">
                    <i
                        class="is-ghost fa-solid fa-compact-disc fa-flip fa-2x"
                    ></i>
                </p>
            </div>
        </div>
        <template v-if="!loading">
            <div @click="goPrevPanel()"
                class="prev-panel-button"
                title="Previous panel">
                <i class="fa-solid fa-arrow-left"></i>
            </div>
            <div @click="movieMode ? goNextPanel() : clickToAdvance()"
                class="next-panel-button"
                title="Next panel">
                <i class="fa-solid fa-arrow-right"></i>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

// TODO: mobile gestures via vue-hammer.js
// https://github.com/excaliburhan/vue-hammerjs#readme

// Draggable elements (later maybe)
// https://dev.to/mandrewcito/vue-js-draggable-div-3mee

export default {
    name: "ComicPanel",
    props: [
        "panelNum",
        "transitionInDuration",
        "transitionOutDuration",
        "isPaused",
        "isActive",
    ],
    // components: {
    //     PausePanel
    // },
    data() {
        return {
            loading: false,
            paused: true,
            panelTimer: {},
            dialogTimer: {},
            dialogDelayTimer: {},
            dialogIndex: 0,
            hideDialog: false,
            playerScale: 1,
            renderComponent: true,
            nextPanelImagesLoaded: 0,
            donePlaying: false,
            minDuration: 0,
            editSpeed: 0.15,
            panelSpeed: 100,
        };
    },
    mounted() {
        // console.log("MOUNTED ComicPanel", this.panelNum);
        // document.body.classList.remove("scanlines");
        this.scalePlayer();
        this.setPanelSpeed();
        if (this.isActive) {
            // this.scalePlayer();
            this.playPanel();
        }
        // console.log("transitionInDuration", this.transitionInDuration);
        // console.log("transitionOutDuration", this.transitionOutDuration);
    },
    created() {
        window.addEventListener("resize", this.scalePlayer);
        window.addEventListener("keydown", this.onKeyPress);
    },
    beforeUnmount() {
        this.killTimers();
        window.removeEventListener("resize", this.scalePlayer);
        window.removeEventListener("keydown", this.onKeyPress);
    },
    watch: {
        isActive(newState, oldState) {
            // console.log("********* WATCH isActive", this.isActive);
            if (newState !== oldState) {
                if (this.isActive) {
                    // this.scalePlayer();
                    this.paused == false;
                    this.playPanel();
                } else {
                    this.paused == true;
                    this.stopAndEdit();
                }
            }
        },
        isPaused() {
            // console.log("********* WATCH isPaused", this.isPaused);
            this.paused = this.isPaused;
            if (this.isPaused == true) {
                this.pauseTimers();
            } else if (this.isActive == true && this.movieMode) {
                this.resumeTimers();
            }
        },
        donePlayingAndDoneLoading(newValue) {
            if (newValue[0] == true && newValue[1] == true)
                this.$emit("nextPanel");
        },
    },
    computed: {
        ...mapGetters([
            "episode",
            "editMode",
            "episodeList",
            "episodeNumber",
            "currentPanel",
            "panelCount",
            "movieMode",
            "readingSpeed",
            "activeModal",
        ]),

        layers() {
            // console.log("this.panelNum", this.panelNum);
            return this.episode.data[this.panelNum].layers;
        },
        dialog() {
            return this.episode.data[this.panelNum].dialog[this.dialogIndex];
        },
        numberOfDialogLines() {
            return this.episode.data[this.panelNum].dialog.length;
        },
        readingTime() {
            let time = 0;
            let reading = this.readingSpeed;
            if (this.movieMode === false) reading = 6;
            if (this.episode.data[this.panelNum].totalReadingTime) {
                time = this.episode.data[this.panelNum].totalReadingTime;
                time = (time * reading) / 10;
            }
            return time;
        },
        routeName() {
            return this.$route.name;
        },
        nextPanelLoaded() {
            // console.log(
            //     "------------ panelNum, panelCount -1",
            //     this.panelNum,
            //     this.episode.panelCount - 1
            // );
            if (this.panelNum >= this.episode.panelCount - 1) return true;
            const nextPanelImageCount =
                this.episode.data[this.panelNum + 1].layers.length;
            if (this.nextPanelImagesLoaded >= nextPanelImageCount) return true;
            return false;
        },
        donePlayingAndDoneLoading() {
            return [this.nextPanelLoaded, this.donePlaying];
        },
    },
    methods: {
        ...mapMutations([
            "setEditMode",
            "goPrevPanel",
            "goNextPanel",
            "goToPanel",
            // "goFirstKeyframe",
            // "goLastKeyframe",
        ]),
        getLayerStyle(layer) {
            const transitionInTime = parseFloat(this.transitionInDuration);
            const transitionOutTime = parseFloat(this.transitionOutDuration);
            const duration =
                transitionInTime + this.panelSpeed + transitionOutTime;

            const startDelay = (layer.frame[0].delay * duration) / 100;
            const endDelay = (layer.frame[1].delay * duration) / 100;

            const speed = Math.max(0, duration - startDelay - endDelay);
            // console.log(">>> getLayerStyle startDelay", startDelay);
            // console.log(">>> getLayerStyle endDelay", endDelay);
            // console.log(">>> getLayerStyle speed", speed);

            const style = {
                "--panel-speed": speed + "s",
                "--panel-delay": startDelay + "s",
                "--panel-x-0": layer.frame[0].x + "px",
                "--panel-y-0": layer.frame[0].y + "px",
                "--panel-opacity-0": layer.frame[0].opacity,
                "--panel-blur-0": layer.frame[0].blur + "px",
                "--panel-rotate-0": layer.frame[0].rotate + "deg",
                "--panel-scale-0": layer.frame[0].scale,

                "--panel-x-1": layer.frame[1].x + "px",
                "--panel-y-1": layer.frame[1].y + "px",
                "--panel-opacity-1": layer.frame[1].opacity,
                "--panel-blur-1": layer.frame[1].blur + "px",
                "--panel-rotate-1": layer.frame[1].rotate + "deg",
                "--panel-scale-1": layer.frame[1].scale,
            };
            // console.log(index + " >> style >>>> '" + layer.name + "'", style);
            return style;
        },
        setImgDimentions(layer, e) {
            if (layer.height === 0) {
                layer.height = e.target.height;
                layer.width = e.target.width;
            }
        },
        onKeyPress(e) {
            if (
                document.activeElement === document.body &&
                this.routeName == "Read" &&
                this.activeModal == false &&
                this.isActive &&
                this.paused == false &&
                this.movieMode === false
            ) {
                if (e.key == " ") {
                    setTimeout(this.clickToAdvance, 250);
                }
            }
        },
        // clickNextArrow(){
        //     if (this.movieMode) this.goNextPanel()
        //     else this.clickToAdvance();
        // },
        clickBackground() {
            // methods check for route state
            // console.log("clickBackground");
            this.deselectAllLayers();
            if (!this.movieMode) this.clickToAdvance();
            else this.pausePanel();
        },
        clickLayer(data) {
            if (this.routeName === "Editor") {
                this.pausePanel();
                if (!this.editMode) {
                    this.stopAndEdit();
                }
                this.$emit("select", { i: data.i, shiftKey: data.shiftKey });
            } else {
                // console.log("clickLayer");
                if (!this.movieMode) this.clickToAdvance();
                else this.pausePanel();
            }
        },
        deselectAllLayers() {
            if (this.routeName === "Editor") {
                this.$emit("deselect");
            }
        },
        clickToAdvance() {
            if (this.isActive && this.currentPanel == this.panelNum) {
                // console.log(
                //     "xxxxxxxxx clickToAdvance",
                //     this.panelNum,
                //     this.currentPanel
                // );
                if (this.dialogIndex < this.numberOfDialogLines - 1)
                    this.dialogTimerDone();
                else this.panelTimerDone();
            }
        },
        pausePanel() {
            if (this.routeName === "Read") {
                // TODO: wrap setTimouts in functions to track remaining
                // https://stackoverflow.com/questions/3969475/javascript-pause-settimeout

                // console.log("pausePanel emit");
                this.$emit("pause");
            }
        },
        scalePlayer() {
            // console.log('.......... scalePlayer', this.$refs.comicWindow, this.$refs.comicContents)
            if (this.$refs.comicWindow && this.$refs.comicContents) {
                const w1 = this.$refs.comicWindow.clientWidth;
                const w2 = this.$refs.comicContents.clientWidth;
                const w = w1 / w2;
                const h1 = this.$refs.comicWindow.clientHeight;
                const h2 = this.$refs.comicContents.clientHeight;
                const h = h1 / h2;
                // console.log('.......... scalePlayer', w1, w1)
                this.playerScale = Math.min(w, h);
            }
        },
        playPanel() {
            this.scalePlayer();
            this.deselectAllLayers();
            this.updateUrl();
            this.startPanelTimer();
            this.startDialogTimer();
            // setTimeout(() => {
            //     this.preloadNextPanel();
            // }, 250);
            setTimeout(this.preloadNextPanel, 250);
            this.$nextTick(() => {
                if (this.isPaused) this.pauseTimers();
            });
        },
        setPanelSpeed() {
            // if (this.editMode) {
            //     this.panelSpeed = this.editSpeed;
            // } else {
            let delayTime = 0;
            // const transitionInTime = parseFloat(this.transitionInDuration);
            // const transitionOutTime = parseFloat(this.transitionOutDuration);
            // console.log('transitionInTime', transitionInTime)
            if (this.episode.data[this.panelNum].totalDelayTime) {
                delayTime = this.episode.data[this.panelNum].totalDelayTime;
                // delayTime = delayTime * this.readingSpeed;
            }
            let minDuration = 0;
            if (this.episode.data[this.panelNum].minDuration) {
                minDuration = this.episode.data[this.panelNum].minDuration;
            }
            // console.log("setPanelSpeed readingTime", this.readingTime);
            // console.log("setPanelSpeed delayTime", this.delayTime);
            // console.log(
            //     "setPanelSpeed transitionInTime",
            //     this.transitionInTime
            // );
            this.panelSpeed = Math.max(
                parseFloat(this.readingTime) + parseFloat(delayTime),
                parseFloat(minDuration)
            );
            // console.log("setPanelSpeed END", this.panelSpeed);
            // }
        },
        // https://stackoverflow.com/questions/16134997/how-to-pause-and-resume-a-javascript-timer

        // const delay = 1000 * 60 * 5; // 5 minutes
        // const timer = setTimeout(() => console.log("Hi"), delay);
        // const timerEnd = new Date().getTime() + delay;

        // // Get ms left to timeout
        // const msToEnd = timerEnd - new Date().getTime();

        startPanelTimer() {
            this.dialogIndex = 0;
            if (
                this.editMode === false &&
                this.movieMode === true &&
                this.panelNum <= this.panelCount - 1
            ) {
                const delay = this.panelSpeed * 1000;
                this.panelTimer.delay = delay;
                this.panelTimer.end = new Date().getTime() + delay;
                this.panelTimer.id = setTimeout(this.panelTimerDone, delay);
            }
        },
        panelTimerDone() {
            clearTimeout(this.panelTimer.id);
            this.panelTimer = {};
            if (this.panelNum >= this.panelCount - 1 || this.editMode) {
                this.stopAndEdit();
            } else {
                this.killTimers();
                this.dialogIndex = 0;
                this.donePlaying = true;
            }
        },
        startDialogTimer() {
            if (this.dialog && this.movieMode === true) {
                if (this.dialog.delay > 0) {
                    this.hideDialog = true;
                    const delay1 = this.dialog.delay * 1000;
                    this.dialogDelayTimer.delay = delay1;
                    this.dialogDelayTimer.end = new Date().getTime() + delay1;
                    this.dialogDelayTimer.id = setTimeout(
                        this.dialogDelayTimerDone,
                        delay1
                    );
                }
                const delay =
                    (this.dialog.readingTime * (this.readingSpeed / 10) +
                        this.dialog.delay) *
                    1000;
                this.dialogTimer.delay = delay;
                this.dialogTimer.end = new Date().getTime() + delay;
                this.dialogTimer.id = setTimeout(this.dialogTimerDone, delay);
            }
        },
        dialogDelayTimerDone() {
            if (this.isPaused) this.pauseTimers();
            else {
                clearTimeout(this.dialogDelayTimer.id);
                this.dialogDelayTimer = {};
                this.hideDialog = false;
            }
        },
        dialogTimerDone() {
            if (this.isPaused) this.pauseTimers();
            else {
                clearTimeout(this.dialogTimer.id);
                this.dialogTimer = {};
                if (this.dialogIndex < this.numberOfDialogLines - 1) {
                    this.dialogIndex++;
                    this.startDialogTimer();
                } else {
                    this.hideDialog = true;
                }
                if (
                    // skip final empty dialog pause
                    this.movieMode === false &&
                    this.dialogIndex == this.numberOfDialogLines - 1 &&
                    this.dialog &&
                    this.dialog.text == ""
                ) {
                    this.panelTimerDone();
                }
            }
        },
        updateUrl() {
            // example: http://localhost:8081/read?episode=0.1
            if (this.routeName == "Read")
                history.replaceState(
                    {},
                    null,
                    this.$route.path +
                        "?episode=" +
                        this.episode.number +
                        "." +
                        this.panelNum
                );
        },
        preloadNextPanel() {
            if (this.panelNum < this.episode.panelCount - 1) {
                // console.log("preloading Panel", this.panelNum + 1);
                const nextPanelLayers =
                    this.episode.data[this.panelNum + 1].layers;
                for (let layer of nextPanelLayers) {
                    // const url = layer.name;
                    // const url = '/episodes/' + this.episode.number + '/' + layer.name
                    // console.log(url);
                    let img = new Image();
                    img.onload = () => {
                        // console.log("img loaded", url);
                        this.imgUrl = img.src;
                        this.nextPanelImagesLoaded++;
                        // this.showImg = true;
                    };
                    img.src =
                        "/episodes/" + this.episode.number + "/" + layer.name;
                }
            }
        },
        stopAndEdit() {
            if (this.routeName === "Editor") {
                this.panelSpeed = this.editSpeed;
                this.setEditMode(true);
                this.dialogIndex = 0;
                this.hideDialog = false;
                this.killTimers();
                this.goToPanel(this.panelNum);
            } else if (this.panelNum === this.panelCount - 1) {
                if (
                    this.episodeNumber !==
                    this.episodeList[this.episodeList.length - 1].number
                )
                    this.$store.dispatch("loadEpisode", {
                        episode: this.episodeNumber + 1,
                        panel: 0,
                    });
                else this.$router.push("/");
            }
        },
        pauseTimers() {
            // console.log("pauseTimers");
            // const msToEnd = timerEnd - new Date().getTime();
            if (this.panelTimer.delay) {
                clearTimeout(this.panelTimer.id);
                this.panelTimer.id = null;
                this.panelTimer.delay =
                    this.panelTimer.end - new Date().getTime();
            }
            if (this.dialogTimer.delay) {
                clearTimeout(this.dialogTimer.id);
                this.dialogTimer.id = null;
                this.dialogTimer.delay =
                    this.dialogTimer.end - new Date().getTime();
            }
            if (this.dialogDelayTimer.delay) {
                clearTimeout(this.dialogDelayTimer.id);
                this.dialogDelayTimer.id = null;
                this.dialogDelayTimer.delay =
                    this.dialogDelayTimer.end - new Date().getTime();
            }
        },
        resumeTimers() {
            // console.log("resumeTimers");
            if (this.panelTimer.delay)
                this.panelTimer.id = setTimeout(
                    this.panelTimerDone,
                    this.panelTimer.delay
                );
            if (this.dialogTimer.delay)
                this.dialogTimer.id = setTimeout(
                    this.dialogTimerDone,
                    this.dialogTimer.delay
                );
            if (this.dialogDelayTimer.delay)
                this.dialogDelayTimer.id = setTimeout(
                    this.dialogDelayTimerDone,
                    this.dialogDelayTimer.delay
                );
        },
        killTimers() {
            // console.log("killTimers");
            if (this.panelTimer.id) {
                clearTimeout(this.panelTimer.id);
            }
            if (this.dialogTimer.id) {
                clearTimeout(this.dialogTimer.id);
            }
            if (this.dialogDelayTimer.id) {
                clearTimeout(this.dialogDelayTimer.id);
            }
            this.panelTimer = {};
            this.dialogTimer = {};
            this.dialogDelayTimer = {};
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/comic.scss";
</style>