<template>
    <div id="comic">
        <template v-for="(panel, index) in panelCount" :key="index">
            <comic-panel
                v-if="
                    index === currentPanelNum || index === currentPanelNum + 1
                "
                :key="index"
                :isActive="isPlaying && index === currentPanelNum"
                :isPaused="
                    paused || (index !== currentPanelNum && !isTransitioning)
                "
                :panelNum="index"
                :transitionInDuration="transitionInDuration"
                :transitionOutDuration="transitionOutDuration"
                :class="isTransitioning ? ['transition', transitionType] : ''"
                :style="{
                    '--duration': transitionInDuration + 's',
                }"
                @nextPanel="transitionToNextPanel"
                @select="selectLayer"
                @deselect="deselectAllLayers"
                @pause="pausePlayer"
            />
        </template>
        <pause-panel v-if="paused" @resume="paused = false" />
        <comic-progress-bar :class="{'fade-out': !showProgressBar && !paused}"/>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ComicPanel from "@/components/ComicPanel.vue";
import PausePanel from "@/components/PausePanel.vue";
import ComicProgressBar from "@/components/ComicProgressBar.vue";

// TODO: mobile gestures via vue-hammer.js
// https://github.com/excaliburhan/vue-hammerjs#readme

export default {
    name: "ComicPlayer",
    props: ["playLoopMode"],
    components: { ComicPanel, PausePanel, ComicProgressBar },
    data() {
        return {
            // isPaused: true,
            isPlaying: true,
            isTransitioning: false,
            transitionTimer: null,
            transitionType: "",
            transitionInDuration: 0,
            transitionOutDuration: 0,
            showProgressBar: false,
        };
    },
    created() {
        window.addEventListener("keydown", this.onKeyPress);
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.onKeyPress);
    },
    mounted() {
        // document.body.classList.remove("scanlines");
        if (this.routeName == "Editor") this.isPlaying = false;
        if (this.currentPanelNum > this.episode.panelCount)
            this.$router.push({
                name: "NotFound",
            });
    },
    computed: {
        ...mapGetters([
            "episode",
            "editMode",
            "movieMode",
            "activeModal",
            "currentPanel",
            "panelCount",
            "paused",
        ]),
        routeName() {
            return this.$route.name;
        },
        currentPanelNum() {
            // console.log("ComicPlayer > currentPanelNum", this.currentPanel);
            return parseInt(this.currentPanel); // force string to number
        },
    },
    methods: {
        ...mapMutations([
            "showModal",
            "setEditMode",
            "goPrevPanel",
            "goNextPanel",
            "goToPanel",
            "setPaused",
        ]),

        startPlaying(panel) {
            // triggered from editor
            this.goToPanel(panel);
            this.isPlaying = true;
        },
        transitionToNextPanel() {
            const n = parseInt(this.currentPanel) + 1;
            const nn = parseInt(this.currentPanel) + 2;
            // console.log("<<---- transitionToNextPanel ---->>", n);
            if (
                this.episode.data &&
                this.episode.data[n] &&
                this.episode.data[n].transition &&
                this.episode.data[n].transition.type != "none"
            ) {
                let fx = this.episode.data[n].transition;

                // apply class + from/to to current and next panel
                this.isTransitioning = true;
                this.transitionType = fx.type;
                this.transitionInDuration = fx.duration;

                if (
                    this.episode.data[nn] &&
                    this.episode.data[nn].transition &&
                    this.episode.data[nn].transition.type != "none"
                ) {
                    this.transitionOutDuration =
                        this.episode.data[nn].transition.duration;
                }

                // let adjustedTimeout = fx.duration * 1000;
                // if (adjustedTimeout > 0) adjustedTimeout + 500;
                this.transitionTimer = setTimeout(() => {
                    this.transitionTimerDone();
                }, fx.duration * 1000);
                // console.log("transitionTimer", this.transitionTimer);
            } else this.goNextPanel();
        },
        transitionTimerDone() {
            // console.log("<<---- timer done ---->>");
            clearTimeout(this.transitionTimer);
            this.transitionTimer = null;
            // this.$nextTick(() => {
            this.isTransitioning = false;
            this.transitionType = "";
            this.goNextPanel();
            // });
        },
        selectLayer({ i, shiftKey }) {
            // console.log("player selectLayer", { i, shiftKey });
            if (this.routeName === "Editor") {
                if (!this.editMode) {
                    this.$emit("stop", this.currentPanel);
                    this.setEditMode(true);
                }
                // console.log("player selectLayer", { i, shiftKey });
                this.$emit("select", { i, shiftKey });
                // } else {
                // clearTimeout(this.transitionTimer);
                // this.transitionTimer = null;
                // this.transitionTimerDone();
                // // this.stopPlaying();
            }
        },
        deselectAllLayers() {
            if (this.routeName === "Editor") {
                this.$emit("deselect");
            }
        },
        onKeyPress(e) {
            if (document.activeElement === document.body) {
                // console.log("keydown =", e.key);
                if (e.key == "q" || e.key == "Q") this.$router.push("/");
                if (e.key == "o" || e.key == "O") {
                    this.pausePlayer();
                    this.showModal("options");
                }
                if (e.key == "ArrowLeft") this.goPrevPanel();
                if (e.key == "ArrowRight") this.goNextPanel();
                if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
                    this.showProgressBar = true;
                    setTimeout(() => {
                        this.showProgressBar = false;
                    }, 500);
                }
                if (this.routeName == "Read" && this.activeModal == false) {
                    if (
                        e.key == "Escape" ||
                        e.key == "p" ||
                        e.key == "Enter" ||
                        (e.key == " " && this.movieMode)
                    )
                        this.togglePause();
                }
            }
        },
        togglePause() {
            if (this.routeName == "Read") {
                if (this.paused) this.setPaused(false);
                else this.pausePlayer();
            }
        },
        unPausePlayer() {
            this.setPaused(false);
        },
        pausePlayer() {
            this.setPaused(true);
            if (this.isTransitioning) this.transitionTimerDone();
        },
        stopPlaying() {
            // TODO: Pause playback, show nav and options
            // console.log("stopPlaying");
            this.isPlaying = false;
        },
        stopAndEdit() {
            // triggered from Editor
            // console.log("stopAndEdit");
            this.isPlaying = false;
        },
    },
};
</script>
