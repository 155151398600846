<template>
    <div class="pause-wrapper">
        <div class="card">
            <header class="card-header" @click="unpause">
                <p class="card-header-title">
                    <span>
                        <i class="fa-solid fa-pause"></i>
                        Paused
                    </span>
                    <button
                        class="delete"
                        @click="unpause"
                        @keyup.enter="unpause"
                        aria-label="close"
                        tabindex="0"
                    ></button>
                </p>
            </header>
            <div class="card-content">
                <div class="content">
                    <!-- <h2 class="subtitle">
                        #{{ episodeNumber }}. {{ episodeTitle }}
                    </h2>
                    <p class="episode-description">{{ episodeDescription }}.</p>
                    <hr /> -->
                    <p v-if="movieMode">
                        You are in movie mode. Press SPACE to pause/unpause.
                    </p>
                    <p v-else>
                        You are in reading mode. Use the SPACE BAR to advance.
                    </p>
                    <p>
                        Use the arrow keys
                        <i class="mr-2 fa-solid fa-arrow-left"></i>
                        <i class="fa-solid fa-arrow-right"></i> to skip ahead or
                        rewind.
                    </p>
                    <p>
                        Click on "Options" to
                        <span v-if="movieMode">adjust reading speed</span>
                        <span v-else>enable movie mode</span>
                        and more.
                    </p>
                    <!--
                        * Show current character(s) w/ their info (behind the
                        scenes). Maybe with their last line of dialog or catch
                        phrase?
                        * Options: Play, reading mode, ~~speed up/down~~, etc.
                        * Navigation: skip, rewind, exit
                        * Idea: episode specific fake ads, wanted posters,
                        journal entries, animal or plant data, etc?
                    -->
                </div>
            </div>
            <footer class="card-footer">
                <a
                    @click="unpause"
                    @keyup.enter="unpause"
                    class="card-footer-item is-primary"
                    title="Play/Pause (p)"
                    tabindex="0"
                    ><i class="mr-3 fa-solid fa-play"></i>Continue</a
                >
                <a
                    @click="goPrevPanel"
                    @keyup.enter="goPrevPanel"
                    class="card-footer-item is-narrow"
                    title="Previous panel (right arrow)"
                    tabindex="0"
                >
                    <i class="fa-solid fa-arrow-left" />
                </a>
                <a
                    @click="goNextPanel"
                    @keyup.enter="goNextPanel"
                    class="card-footer-item is-narrow"
                    title="Next panel (left arrow)"
                    tabindex="0"
                >
                    <i class="fa-solid fa-arrow-right" />
                </a>
                <a
                    @click="toggleFullScreen"
                    @keyup.enter="toggleFullScreen"
                    class="card-footer-item is-narrow"
                    title="Full screen (F11)"
                    tabindex="0"
                >
                    <i v-if="isFullScreen" class="fa-solid fa-compress"></i>
                    <i v-else class="fa-solid fa-expand"></i>
                </a>
                <a
                    @click="showOptions"
                    @keyup.enter="showOptions"
                    class="card-footer-item"
                    title="Options (o)"
                    tabindex="0"
                    ><i class="fa-solid fa-wrench"></i
                    ><span class="ml-3 is-hidden-mobile">Options</span></a
                >
                <router-link to="/" title="Quit (q)" class="card-footer-item">
                    <span class="mr-3 is-hidden-mobile">Quit</span>
                    <img src="@/assets/img/favicon.png" style="width: 1.25em" />
                    <!-- <i class="ml-3 fa-solid fa-arrow-right-from-bracket"></i -->
                </router-link>
            </footer>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "PausePanel",
    data() {
        return {
            // isPaused: true,
        };
    },
    computed: {
        ...mapGetters([
            "paused",
            "movieMode",
            "episodeTitle",
            "episodeNumber",
            "episodeDescription",
            "currentPanel",
            "panelCount",
        ]),
        isFullScreen() {
            return (
                window.innerHeight == screen.height ||
                document.fullscreenElement
            );
        },
        // percentDone() {
        //     const percent = (this.currentPanel / this.panelCount) * 100;
        //     return parseInt(percent);
        //     // Panel {{ currentPanel + 1 }} of {{ panelCount }}
        // },
    },
    methods: {
        ...mapMutations([
            "showModal",
            "setPaused",
            "goPrevPanel",
            "goNextPanel",
        ]),

        unpause() {
            this.setPaused(false);
            // this.$emit("resume");
        },
        toggleFullScreen() {
            if (this.isFullScreen) {
                document.exitFullscreen();
            } else document.documentElement.requestFullscreen();
        },
        showOptions() {
            this.showModal("options");
        },
    },
};
</script>